import { NgModule } from '@angular/core';
import { DraggableDirective } from './directives/draggable.directive';
import { DraggableHelperDirective } from './directives/draggable-helper.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { SortableListDirective } from './directives/sortable-list.directive';
import { SortableDirective } from './directives/sortable.directive';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    DraggableDirective,
    DraggableHelperDirective,
    SortableListDirective,
    SortableDirective
  ],
  imports: [
    CommonModule,
    OverlayModule
  ],
  exports: [
    DraggableDirective,
    DraggableHelperDirective,
    SortableListDirective,
    SortableDirective
  ]
})
export class DraggableModule {
}
