import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { CoreModule } from '../core/core.module';
import { UserModule } from './modules/user/user.module';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { CacheRouteReuseStrategy } from './modules/orders/strategies/cache-route-reuse.strategy';

@NgModule({
  declarations: [MainLayoutComponent],
  imports: [
    CommonModule,
    CoreModule,
    UserModule,
    RouterModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy }
  ]
})
export class HomeModule {
  constructor() {}
}
