<div class="bs-checkbox" [class.centered]="centered" [class.aligned]="!centered">
  <div class="bs-checkbox__holder" *ngIf="switcher; else classic">
    <label class="switch" [class.disabled]="disabled">
      <input
        type="checkbox"
        [disabled]="disabled"
        [(ngModel)]="state"
        (ngModelChange)="change()"
      />
      <span class="slider round"></span>
    </label>
  </div>
  <ng-template #classic>
    <div class="bs-checkbox__holder">
      <input
        type="checkbox"
        [disabled]="disabled"
        [(ngModel)]="state"
        (ngModelChange)="change()"
      />
      <div class="bs-checkbox__holder__visible"></div>
    </div>
  </ng-template>
  <span class="bs-checkbox__label" *ngIf="label">{{ label }}</span>
</div>

