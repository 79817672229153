import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private emitter = new EventEmitter();

  constructor() {}

  public get sitesChanged(): Observable<any> {
    return this.emitter.asObservable();
  }

  emitEvent(data: any) {
    this.emitter.emit(data);
  }
}
