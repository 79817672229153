import { Routes } from '@angular/router';
import { HomeRoutes } from './modules/home/home.routes';
import { MainLayoutComponent } from './modules/home/components/main-layout/main-layout.component';
import { NotFoundComponent } from './modules/core/components/not-found/not-found.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      ...HomeRoutes
    ]
  },
  {
    path: '**',
    redirectTo: 'not-found'
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  }
];
