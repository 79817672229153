import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bs-main',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('content') content;
  lastUrl = '';
  preservedScrollTop = 0;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        e => {
          if (e instanceof NavigationStart) {
            if (e.url.indexOf('orders/details') !== -1) {
              this.preservedScrollTop = this.content.nativeElement.scrollTop;
              this.lastUrl = e.url;
            }
          } else if (e instanceof NavigationEnd) {
            if (this.lastUrl.indexOf('orders/details') !== -1 && e.url === '/orders') {
              this.content.nativeElement.scrollTop = this.preservedScrollTop;
            } else {
              this.content.nativeElement.scrollTop = 0;
            }
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
