<div class="bs-input">
  <input
    placeholder="Choose Date"
    [owlDateTime]="dt1"
    [owlDateTimeTrigger]="dt1"
    [selectMode]="'range'"
    class="input"
    [ngClass]="{disabled: disabled}"
    [(ngModel)]="value"
    (ngModelChange)="change()"
    [disabled]="disabled"
  />
  <owl-date-time
    #dt1
    [hour12Timer]="timeFormat12h"
    [startAt]="startAt"
    [endAt]="endAt"
  ></owl-date-time>
  <span class="bs-input-border"></span>
</div>
