import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ButtonComponent } from './components/button/button.component';
import { RangeInputComponent } from './components/range-input/range-input.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DraggableModule } from './modules/draggable/draggable.module';
import { FooterComponent } from './components/footer/footer.component';
import { InputComponent } from './components/input/input.component';
import { AlertComponent } from './components/alert/alert.component';
import { SortIndicatorComponent } from './components/sort-indicator/sort-indicator.component';
import { NumberFilterDirective } from './directives/number-filter.directive';
import { FormControlComponent } from './components/form-control/form-control.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { RadioComponent } from './components/radio/radio.component';
import { DateTimeRangePickerComponent } from './components/date-time-range-picker/date-time-range-picker.component';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { LogoComponent } from './components/logo/logo.component';
import { ExtendedDateTimePickerComponent } from './components/extended-date-time-picker/extended-date-time-picker.component';

@NgModule({
  declarations: [
    ButtonComponent,
    RangeInputComponent,
    NavbarComponent,
    ConfirmationComponent,
    CheckboxComponent,
    NotFoundComponent,
    FooterComponent,
    InputComponent,
    AlertComponent,
    SortIndicatorComponent,
    NumberFilterDirective,
    FormControlComponent,
    TextareaComponent,
    RadioComponent,
    DateTimeRangePickerComponent,
    LogoComponent,
    ExtendedDateTimePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FontAwesomeModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgSelectModule,
    ModalModule.forRoot(),
    DraggableModule,
    TextFieldModule,
    OwlDateTimeModule
  ],
    exports: [
        ButtonComponent,
        RangeInputComponent,
        FontAwesomeModule,
        NavbarComponent,
        NgSelectModule,
        CheckboxComponent,
        DraggableModule,
        FooterComponent,
        InputComponent,
        SortIndicatorComponent,
        FormControlComponent,
        TextareaComponent,
        RadioComponent,
        DateTimeRangePickerComponent,
        LogoComponent,
        ExtendedDateTimePickerComponent
    ],
  providers: []
})
export class CoreModule {
}
