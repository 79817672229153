<div class="bs-modal-content">
  <div
    class="bs-modal-content__close"
    (click)="closeAlert()"
  >
    <fa-icon [icon]="ICONS.CLOSE"></fa-icon>
  </div>
  <div class="bs-modal-content__header">
    <h2>
      {{ title }}
    </h2>
  </div>
  <div class="bs-modal-content__body">
    {{ text }}
  </div>
  <div class="bs-modal-content__footer">
    <bs-button
      value="OK"
      (click)="closeAlert()"
      [type]="BSButtonTypes.PRIMARY"
      size="extra-large"
    ></bs-button>
  </div>
</div>
