<div class="container profile" *ngIf="userData">
  <h1 class="profile__heading">
    My Profile
  </h1>
  <form [formGroup]="infoForm">
    <h2 class="profile__subheading">
      Information
    </h2>
    <div class="profile__form">
      <div class="profile__form__column">
        <div class="control">
          <bs-form-control
            label="Username"
            [isDisabled]="true"
          >
            <ng-template #controlTemplate>
              <bs-input formControlName="userName"></bs-input>
            </ng-template>
          </bs-form-control>
        </div>
        <div class="control">
          <bs-form-control
            label="Email"
            [isDisabled]="true"
          >
            <ng-template #controlTemplate>
              <bs-input formControlName="email"></bs-input>
            </ng-template>
          </bs-form-control>
        </div>
      </div>
      <div class="profile__form__column">
        <div class="control">
          <bs-form-control
            label="First name"
            [isInvalid]="isInvalid('firstName')"
            [hasFooter]="true"
          >
            <ng-template #controlTemplate>
              <bs-input
                formControlName="firstName"
                [isInvalid]="isInvalid('firstName')"
              ></bs-input>
            </ng-template>
            <ng-template #footerTemplate>
              <span
                class="validation-error"
                *ngIf="isInvalid('firstName') && f('firstName').errors.required"
              >First name is required</span>
            </ng-template>
          </bs-form-control>
        </div>
        <div class="control">
          <bs-form-control
            label="Last name"
            [isInvalid]="isInvalid('lastName')"
            [hasFooter]="true"
          >
            <ng-template #controlTemplate>
              <bs-input
                formControlName="lastName"
                [isInvalid]="isInvalid('lastName')"
              ></bs-input>
            </ng-template>
            <ng-template #footerTemplate>
              <span
                class="validation-error"
                *ngIf="isInvalid('lastName') && f('lastName').errors.required"
              >Last name is required</span>
            </ng-template>
          </bs-form-control>
        </div>
      </div>
      <div class="profile__form__column">
        <div class="control">
          <bs-form-control
            label="New password"
          >
            <ng-template #controlTemplate>
              <bs-input
                formControlName="newPassword"
                type="password"
              ></bs-input>
            </ng-template>
          </bs-form-control>
        </div>
        <div class="control">
          <bs-form-control
            label="Retype password"
            [isDisabled]="infoForm.controls.retypePassword.disabled"
            [isInvalid]="isInvalid('retypePassword')"
            [hasFooter]="true"
          >
            <ng-template #controlTemplate>
              <bs-input
                formControlName="retypePassword"
                type="password"
                [isInvalid]="isInvalid('retypePassword')"
              ></bs-input>
            </ng-template>
            <ng-template #footerTemplate>
              <span
                class="validation-error"
                *ngIf="isInvalid('retypePassword') && f('retypePassword').errors.required"
              >Please retype new password</span>
            </ng-template>
          </bs-form-control>
        </div>
      </div>
    </div>
    <h2 class="profile__subheading">
      Settings
    </h2>
    <div class="profile__form">
      <div class="profile__form__column">
        <div class="control">
          <bs-form-control label="Timezone">
            <ng-template #controlTemplate>
              <ng-select
                formControlName="timeZone"
                [items]="timezones"
                class="form-select"
                [clearable]="false"
                [multiple]="false"
                bindLabel="displayName"
                bindValue="name"
                groupBy="region"
              ></ng-select>
            </ng-template>
          </bs-form-control>
        </div>
        <div class="control">
          <bs-form-control label="Date format">
            <ng-template #controlTemplate>
              <ng-select
                formControlName="dateFormat"
                [items]="settingsService.dateFormats"
                bindValue="name"
                bindLabel="displayName"
                class="form-select"
                [clearable]="false"
                [multiple]="false"
              ></ng-select>
            </ng-template>
          </bs-form-control>
        </div>
      </div>
      <div class="profile__form__column">
        <div class="control">
          <bs-form-control label="Language">
            <ng-template #controlTemplate>
              <ng-select
                formControlName="language"
                [items]="languages"
                class="form-select"
                [clearable]="false"
                [multiple]="false"
              ></ng-select>
            </ng-template>
          </bs-form-control>
        </div>
        <div class="control">
          <bs-form-control label="Date separators">
            <ng-template #controlTemplate>
              <ng-select
                formControlName="dateSeparator"
                [items]="settingsService.dateSeparators"
                bindValue="name"
                bindLabel="displayName"
                class="form-select"
                [clearable]="false"
                [multiple]="false"
              ></ng-select>
            </ng-template>
          </bs-form-control>
        </div>
      </div>
      <div class="profile__form__column">
        <div class="control">
          <bs-form-control label="Time format">
            <ng-template #controlTemplate>
              <ng-select
                formControlName="timeFormat"
                [items]="settingsService.timeFormats"
                bindValue="name"
                bindLabel="displayName"
                class="form-select"
                [clearable]="false"
                [multiple]="false"
              ></ng-select>
            </ng-template>
          </bs-form-control>
        </div>
        <div class="profile__form__column separated">
          <div class="control">
            <bs-form-control label="Decimal separators">
              <ng-template #controlTemplate>
                <ng-select
                  formControlName="decimalSeparator"
                  [items]="settingsService.decimalSeparators"
                  bindLabel="displayName"
                  bindValue="name"
                  class="form-select"
                  [clearable]="false"
                  [multiple]="false"
                ></ng-select>
              </ng-template>
            </bs-form-control>
          </div>
          <div class="control">
            <bs-form-control label="Thousands separators">
              <ng-template #controlTemplate>
                <ng-select
                  formControlName="thousandsSeparator"
                  [items]="settingsService.thousandSeparators"
                  bindLabel="displayName"
                  bindValue="name"
                  class="form-select"
                  [clearable]="false"
                  [multiple]="false"
                ></ng-select>
              </ng-template>
            </bs-form-control>
          </div>
        </div>
      </div>
    </div>
  </form>
  <h2 *ngIf="userSites.length > 0" class="profile__subheading">
    Sites
  </h2>
  <div class="profile__sites">
    <table *ngIf="userSites.length > 0" class="custom-table">
      <thead>
      <tr>
        <th>Client</th>
        <th>Site</th>
        <th>Default</th>
        <th>Role</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let client of userSites">
        <tr *ngFor="let site of client.sites; let i = index">
          <td *ngIf="i === 0" [rowSpan]="client.sites.length" class="brand">{{ client.name }}</td>
          <td>{{ site.name }}</td>
          <td>
            <bs-checkbox [(ngModel)]="site.isDefault" [switcher]="true"></bs-checkbox>
          </td>
          <td style="text-transform: capitalize;">{{ site.role }}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div class="profile__controls">
    <bs-button [value]="'Save changes'" [outline]="false" [type]="BSButtonTypes.PRIMARY" (click)="applyChanges()"></bs-button>
  </div>
</div>
