import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../core/models/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { TimezoneService } from '../core/services/timezone.service';
import { SimpleSearchService } from '../home/modules/orders/services/simple-search.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public token: string;
  public currentUser: Observable<User>;

  constructor(private httpClient: HttpClient, private timezoneService: TimezoneService, private simpleSearchService: SimpleSearchService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    this.currentUserSubject.next(null);
    return this.httpClient.post(
      `${environment.apiUrl}/oauth/token`,
      `grant_type=password&username=${username}&password=${encodeURIComponent(password)}`,
      {
        headers: new HttpHeaders({
          'Content-type': 'application/x-www-form-urlencoded',
          authorization: `Basic ${window.btoa(`${environment.clientId}:${environment.clientSecret}`)}`
        })
      }
    ).pipe(
      map((data: any) => {
        const token = data.access_token;
        this.token = token;
        return token;
      }),
      switchMap(token => {
        return this.httpClient.get(
          `${environment.apiUrl}/user/`,
          {
            headers: new HttpHeaders({
              authorization: `Bearer ${token}`
            })
          }
        );
      }),
      map((user: User) => {
        user.token = this.token;
        user.userClientRoles = user.userClientRoles.filter(clientRole => clientRole.permissions.length > 0);
        user.userClientRoles.forEach(role => role.checked = role.default);
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.simpleSearchService.simpleSearchValue = {
      searchString: '',
      dateFrom: ''
    };
  }

  changePassword(password, matchingPassword, token?): Observable<any> {
    if (!token) {
      token = this.token || this.currentUserValue.token;
    }

    this.currentUserSubject.next(null);

    return this.httpClient.post(
      `${environment.apiUrl}/user/password/reset`,
      { password, matchingPassword },
      {
        headers: new HttpHeaders({
          authorization: `Bearer ${token}`
        })
      }
    );
  }

  resetPassword(email: string): Observable<any> {
    this.currentUserSubject.next(null);
    return this.httpClient.post(`${environment.apiUrl}/user/password/forgot`, email, {
      headers: new HttpHeaders({
        'Content-type': 'text/plain'
      })
    });
  }

  public updateUser(user: User, keepClientRoles: boolean, keepTimezone: boolean = true): void {
    user.token = this.currentUserValue.token;

    if (keepClientRoles) {
      user.userClientRoles = this.currentUserValue.userClientRoles;
    }

    if (keepTimezone) {
      user.timeZone = this.timezoneService.timezone$.value.name;
    }

    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  public hasPermissionForOrder(clientId: number, subClientId: string, permission: string): boolean {
    const { userClientRoles } = this.currentUserValue;
    let result = false;

    for (const role of userClientRoles) {
      if (role.clientId === clientId && role.subClientId === subClientId) {
        result = role.permissions.includes(permission);

        if (result) {
          break;
        }
      }
    }

    return result;
  }
}
