import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwnProfileComponent } from './components/own-profile/own-profile.component';
import { UserRoutes } from './user.routes';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [OwnProfileComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(UserRoutes),
    CoreModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class UserModule { }
