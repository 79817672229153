import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import { RangeOption } from '../models/range-option.model';
import { SimpleSearchDto } from '../models/simple-search-dto-model';

@Injectable({
  providedIn: 'root'
})
export class SimpleSearchService {
  public rangeOptions: RangeOption[] = [
    {
      value: '10d',
      displayText: '10 days',
      momentValue: {
        amount: 10,
        unit: 'days'
      }
    },
    {
      value: 'currentMonth',
      displayText: 'Current month'
    },
    {
      value: '1m',
      displayText: '1 month',
      momentValue: {
        amount: 1,
        unit: 'months'
      }
    },
    {
      value: '2m',
      displayText: '2 months',
      momentValue: {
        amount: 2,
        unit: 'months'
      }
    },
    {
      value: '3m',
      displayText: '3 months',
      momentValue: {
        amount: 3,
        unit: 'months'
      }
    },
    {
      value: '6m',
      displayText: '6 months',
      momentValue: {
        amount: 6,
        unit: 'months'
      }
    },
    {
      value: '12m',
      displayText: '12 month',
      momentValue: {
        amount: 12,
        unit: 'months'
      }
    }
  ];

  private simpleSearchSubject = new BehaviorSubject<{value: SimpleSearchDto, search: boolean}>(undefined);

  constructor() { }

  get simpleSearch$(): Observable<{value: SimpleSearchDto, search: boolean}> {
    return this.simpleSearchSubject.asObservable();
  }

  startSearch(searchString: string, dateFrom: string) {
    const value: SimpleSearchDto = {
      searchString,
      dateFrom: this.calculateDateFrom(dateFrom)
    };

    this.simpleSearchSubject.next({ value, search: true });
  }

  get simpleSearchValue(): SimpleSearchDto {
    return this.simpleSearchSubject.value?.value || {
      searchString: '',
      dateFrom: this.calculateDateFrom(this.rangeOptions.find(option => option.value === '10d').value)
    };
  }

  set simpleSearchValue(value: SimpleSearchDto) {
    this.simpleSearchSubject.next({ value, search: false });
  }

  public calculateDateFrom(value: string): string {
    let result: string;

    if (value === 'currentMonth') {
      result = moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    } else {
      const { momentValue } = this.rangeOptions.find(option => option.value === value);
      result = moment().subtract(momentValue.amount, momentValue.unit).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }

    return result;
  }
}
