import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bs-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {
  @Input() value: string;
  @Input() type: string;
  @Input() outline: boolean;
  @Input() isDisabled: boolean;
  @Input() icon: any;
  @Input() size: string;
}
