import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private authService: AuthService) {}

  get decimal(): string {
    return this.decimalSeparators.find(separator => {
      return this.authService.currentUserValue.decimalSeparator === separator.name ||
        this.authService.currentUserValue.decimalSeparator === separator.displayName;
    }).symbol || '.';
  }

  get thousand(): string {
    return this.thousandSeparators.find(separator => {
      return this.authService.currentUserValue.thousandsSeparator === separator.name ||
        this.authService.currentUserValue.thousandsSeparator === separator.displayName;
    }).symbol || '';
  }

  get dateFormat(): string {
    return (this.dateFormats.find(format => {
      return this.authService.currentUserValue.dateFormat === format.name ||
        this.authService.currentUserValue.dateFormat === format.displayName;
    }).pattern || 'dd/MM/yy').replace(/\//g, this.date);
  }

  get date(): string {
    return this.dateSeparators.find(separator => {
      return this.authService.currentUserValue.dateSeparator === separator.name ||
        this.authService.currentUserValue.dateSeparator === separator.displayName;
    }).symbol || '';
  }

  get time(): string {
    return this.timeFormats.find(format => {
      return this.authService.currentUserValue.timeFormat === format.name ||
        this.authService.currentUserValue.timeFormat === format.displayName;
    }).pattern || 'HH:mm:ss';
  }

  get fullDatePattern(): string {
    let pattern = this.dateFormat;
    pattern = pattern.replace(/\//g, this.date);
    pattern += ', ' + this.time;
    return pattern;
  }

  public decimalSeparators = [
    { name: 'comma', symbol: ',', displayName: 'Comma ","' },
    { name: 'dot', symbol: '.', displayName: 'Dot "."' }
  ];

  public thousandSeparators = [
    { name: 'none', symbol: '', displayName: 'None ""' },
    { name: 'dot', symbol: '.', displayName: 'Dot "."' },
    { name: 'comma', symbol: ',', displayName: 'Comma ","' },
    { name: 'space', symbol: ' ', displayName: 'Space " "' }
  ];

  public dateFormats = [
    { name: 'd/m/y', pattern: 'dd/MM/yy', displayName: 'DD/MM/YY' },
    { name: 'm/d/y', pattern: 'MM/dd/yy', displayName: 'MM/DD/YY' },
    { name: 'dmy', pattern: 'dd MMMM, yyyy', displayName: 'Day Month, Year' },
    { name: 'mdy', pattern: 'MMMM dd, yyyy', displayName: 'Month Day, Year' }
  ];

  public dateSeparators = [
    { name: 'dot', symbol: '.', displayName: 'Dot "."' },
    { name: 'slash', symbol: '/', displayName: 'Slash "/"' }
  ];

  public timeFormats = [
    { name: '24h', pattern: 'HH:mm:ss', displayName: '24H' },
    { name: '12h', pattern: 'hh:mm:ss a', displayName: '12H' }
  ];
}
