import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private languagesList: Array<string> = ['ENG'];

  public get languages(): Array<string> {
    return this.languagesList;
  }
}
