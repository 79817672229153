import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[bsNumberFilter]'
})
export class NumberFilterDirective {
  @Input() bsNumberFilter: boolean;
  @Input() allowOperations: boolean;
  private availableCodes = [46, 8, 9, 27, 13];

  constructor() {}

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;

    if (this.bsNumberFilter) {
      const key = e.keyCode;

      if (this.availableCodes.indexOf(key) !== -1 || this.isOperation(e, key)) {
        return;
      }

      if ((e.shiftKey || (key < 48 || key > 57)) && (key < 96 || key > 105)) {
        e.preventDefault();
      }
    }
  }

  private isOperation(e: KeyboardEvent, key: number): boolean {
    if (this.allowOperations) {
      return (key === 65 && (e.ctrlKey || e.metaKey)) ||
        (key === 67 && (e.ctrlKey || e.metaKey)) ||
        (key === 86 && (e.ctrlKey || e.metaKey)) ||
        (key === 88 && (e.ctrlKey || e.metaKey)) ||
        (key >= 35 && key <= 39);
    } else {
      return false;
    }
  }
}
