import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(err => {
          switch (err.status) {
            case 400: {
              const errors = err.error?.errors;
              if (errors) {
                const errorMessage = errors.map(error => {
                  return error.defaultMessage;
                });

                return throwError(errorMessage.join(''));
              } else if (err.error?.errorMessage) {
                return throwError(err.error?.errorMessage);
              } else {
                return throwError('Unknown error. Status code: 400');
              }
            }

            case 401: {
              const errorType = err.error?.error?.toLowerCase();
              if (errorType === 'invalid_token') {
                this.router.navigate(['/login'], { queryParams: { sessionEnded: true, returnUrl: this.router.url } });
                return throwError(err.error?.error_description);
              } else if (errorType === 'unauthorized') {
                return throwError(err.error?.error_description || err.error?.message);
              } else {
                this.authService.logout();
                this.router.navigate(['/login'], { queryParams: { sessionEnded: true, returnUrl: this.router.url } });
                return EMPTY;
              }
            }

            case 403: {
              if (err.error.errorType === 'PASSWORD_RESET_REQUIRED') {
                this.router.navigate(['/change-password']);
                return throwError(null);
              }

              return throwError(err.error);
            }

            case 500: {
              const error = err.error.error_description ? err.error.error_description : err.error.errorMessage ? err.error.errorMessage : 'Unknown error';
              return throwError(error);
            }

            default: {
              const error = err.error.errorMessage || err.statusText;
              return throwError(error);
            }
          }
        })
      );
  }
}
