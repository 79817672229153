import { Component } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export interface ExtendedDateTime {
  date: Array<Date | any>;
  offset: number;
}

@Component({
  selector: 'bs-extended-date-time-picker',
  templateUrl: './extended-date-time-picker.component.html',
  styleUrls: ['./extended-date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ExtendedDateTimePickerComponent,
      multi: true
    }
  ]
})
export class ExtendedDateTimePickerComponent implements ControlValueAccessor {
  public value: ExtendedDateTime = { date: [], offset: 0 };
  public disabled: boolean;

  private onChange = (value: ExtendedDateTime) => {};
  private onTouched = () => {};

  constructor() { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: ExtendedDateTime): void {
    this.value = obj;
  }

  change(): void {
    this.onChange(this.value);
  }
}
