<div class="container h-100 d-flex justify-content-center flex-column">
  <div class="row">
    <div class="col d-flex justify-content-center">
      <span class="error-number">
        404
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-center">
      <span class="error-text">
        Something went wrong...
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-center">
      <span class="error-resolve">
        We can't find the page you are looking for.
        <br>
        <a routerLink="/">Let's try again</a>
      </span>
    </div>
  </div>
</div>
