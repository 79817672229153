<div class="holder">
  <div class="change">
    <div class="change__title">
      <h1>Password Changing</h1>
    </div>
    <div class="change__form" *ngIf="changePasswordForm">
      <form [formGroup]="changePasswordForm" (keyup.enter)="changePassword()">
        <div class="change__form__control">
          <label class="change__form__control__label">Your new password</label>
          <bs-input
            class="change__form__control__input"
            placeholder="Enter password"
            type="password"
            formControlName="password"
          ></bs-input>
        </div>
        <div class="change__form__actions">
          <bs-button
            [type]="BSButtonTypes.PRIMARY"
            value="Change Password"
            [isDisabled]="!changePasswordForm.valid"
            (click)="changePassword()"
          ></bs-button>
        </div>
      </form>
    </div>
  </div>
  <div class="info">
    <h1>Any issues?</h1>
    <div class="info__group">
      <p>Don't worry. You could always restore password using your email.</p>
    </div>
  </div>
</div>
