import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { FocusOnInputService } from '../../services/focus-on-input.service';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'bs-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputComponent,
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor, OnChanges, OnInit {
  @Input() placeholder: string;
  @Input() width: number;
  @Input() isInvalid: boolean;
  @Input() type: string;
  @Input() autofocus: boolean;
  @Input() focusOnInput: boolean;
  @Input() height: 'low' | 'high' | '';
  @Input() min: number;

  public value: string;
  public disabled: boolean;
  private needToFocus = false;

  @ViewChild('bsInput') bsInput: ElementRef;

  private onChange = (value: string) => {};
  private onTouched = () => {};

  constructor(private focusOnInputService: FocusOnInputService) {}

  ngOnInit(): void {
    this.focusOnInputService.inputFocus$.subscribe(value => {
      this.needToFocus = value;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.focusOnInput?.currentValue) {
      fromEvent(document, 'keyup').pipe(
        filter(() => {
          return !this.needToFocus;
        }),
        filter((event: KeyboardEvent) => {
          if (this.focusOnInput) {
            return ((event.keyCode > 47 && event.keyCode < 58) ||
              (event.keyCode > 64 && event.keyCode < 91) ||
              (event.keyCode > 95 && event.keyCode < 106)) && !this.isOperation(event);
          }
        })
      ).subscribe(event => {
        this.writeValue(event.key);
        this.bsInput.nativeElement.focus();
      });
    }
  }

  emitFocusEvent(value: boolean): void {
    if (value && this.focusOnInput) {
      this.bsInput.nativeElement.select();
    }

    this.focusOnInputService.inputFocus = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: string): void {
    this.value = obj || '';
  }

  change(): void {
    this.onChange(this.value);
  }

  isOperation(e: KeyboardEvent): boolean {
    return (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode >= 35 && e.keyCode <= 39);
  }
}
