<div class="wrapper">
  <div class="split left"></div>
  <div class="split right"></div>
  <div class="container">
    <div class="logo">
      <div class="logo-holder">
        <bs-logo color="black"></bs-logo>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
