import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
  private localizationsList: Array<string> = ['EN', 'DE', 'ES', 'IT', 'RU'];

  public get localizations(): Array<string> {
    return this.localizationsList;
  }
}
