import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RadioConfigurationModel } from '../../models/radio-configuration.model';

@Component({
  selector: 'bs-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent {
  @Input() configuration: Array<RadioConfigurationModel>;
  @Input() value: string;
  @Input() disabled: boolean;
  @Output() valueChange = new EventEmitter<string>();

  change(newValue: string): void {
    if (!this.disabled) {
      this.value = newValue;
      this.valueChange.emit(this.value);
    }
  }
}
