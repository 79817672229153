import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ButtonTypes } from '../../types/button-types.enum';
import { faCheckCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'bs-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {
  BSButtonTypes = ButtonTypes;
  ICONS = {
    YES: faCheckCircle,
    NO: faTimesCircle,
    CLOSE: faTimes
  };

  public text: string;
  public title: string;
  public dontShowMessage: boolean;
  public showDontShowAgainMessage: boolean;
  public result = false;

  constructor(public bsModalRef: BsModalRef) { }

  public passConfirmation(result: boolean): void {
    this.result = result;
    this.bsModalRef.hide();
  }
}
