import { Component } from '@angular/core';
import { ButtonTypes } from '../../types/button-types.enum';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'bs-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  BSButtonTypes = ButtonTypes;

  ICONS = {
    CLOSE: faTimes
  };

  public text: string;
  public title: string;
  constructor(public bsModalRef: BsModalRef) { }

  public closeAlert(): void {
    this.bsModalRef.hide();
  }
}
