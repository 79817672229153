import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ButtonTypes } from '../../core/types/button-types.enum';
import { AuthService } from '../auth.service';
import { AlertService } from '../../core/services/alert.service';

@Component({
  selector: 'bs-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  ICONS = {
    BACK: faArrowLeft
  };

  ButtonTypesEnum = ButtonTypes;

  resetPasswordForm: FormGroup;
  resetPasswordRequestIsSent = false;

  constructor(private authService: AuthService, private alertService: AlertService) {
    this.createForm();
  }

  createForm(): void {
    this.resetPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  resetPassword(): void {
    if (this.resetPasswordForm.valid) {
      this.authService.resetPassword(this.resetPasswordForm.value.email).subscribe(_ => {
        this.resetPasswordRequestIsSent = true;
        this.resetPasswordForm.reset();
      }, err => {
        this.alertService.showAlert({ title: 'Failure', text: 'Password was not reset' });
      });
    }
  }
}
