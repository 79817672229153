import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'bs-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss']
})
export class FormControlComponent {
  @Input() label: string;
  @Input() isInvalid: boolean;
  @Input() hasFooter: boolean;
  @Input() isDisabled: boolean;
  @ContentChild('controlTemplate') controlTemplate: TemplateRef<any>;
  @ContentChild('footerTemplate') footerTemplate: TemplateRef<any>;

  constructor() {}
}
