/* tslint:disable:no-string-literal */
import { DetachedRouteHandle, RouteReuseStrategy, ActivatedRouteSnapshot } from '@angular/router';
import { ComponentRef } from '@angular/core';

export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  handlers: {[key: string]: DetachedRouteHandle} = {};

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!this.getKey(route)) {
      return null;
    }

    return this.handlers[this.getKey(route)];
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route.data && route.data.shouldClearAllReusedComponents) {
      this.clear();
    }

    return !!this.getKey(route) && !!this.handlers[this.getKey(route)];
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data && !!(route.data as any).shouldBeReused;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    this.handlers[this.getKey(route)] = handle;
  }

  clear(): void {
    for (const key in this.handlers) {
      if (this.handlers.hasOwnProperty(key)) {
        const handler: DetachedRouteHandle = this.handlers[key];
        const componentRef: ComponentRef<any> = handler['componentRef'];

        if (componentRef) {
          componentRef.destroy();
        }
      }
    }

    this.handlers = {};
  }

  getKey(route: ActivatedRouteSnapshot): string {
    if (route.data && route.data.key) {
      return route.data.key;
    } else {
      return '';
    }
  }
}
