<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col justify-content-start">
        <div class="logo">
          <bs-logo color="white"></bs-logo>
        </div>
      </div>
      <div class="col justify-content-end">© {{year}} Bluesail. All rights reserved.</div>
    </div>
  </div>
</div>
