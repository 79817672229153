<div class="bs-modal-content">
  <div
    class="bs-modal-content__close"
    (click)="passConfirmation(false)"
  >
    <fa-icon [icon]="ICONS.CLOSE"></fa-icon>
  </div>
  <div class="bs-modal-content__header">
    <h2>
      {{ title }}
    </h2>
  </div>
  <div class="bs-modal-content__body">
    {{ text }}
    <div class="dont-show" *ngIf="showDontShowAgainMessage">
      <bs-checkbox
        [(ngModel)]="dontShowMessage"
        [switcher]="false"
      ></bs-checkbox>
      <span>- don't show this message again</span>
    </div>
  </div>
  <div class="bs-modal-content__footer">
    <bs-button
      value="Cancel"
      (click)="passConfirmation(false)"
      [type]="BSButtonTypes.SECONDARY"
      size="extra-small"
    ></bs-button>
    <bs-button
      value="OK"
      (click)="passConfirmation(true)"
      [type]="BSButtonTypes.PRIMARY"
      size="extra-small"
    ></bs-button>
  </div>
</div>
