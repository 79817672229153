import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FocusOnInputService {
  private inputFocusSubject = new Subject();

  constructor() { }

  get inputFocus$(): Observable<any> {
    return this.inputFocusSubject.asObservable();
  }

  set inputFocus(value: boolean) {
    this.inputFocusSubject.next(value);
  }
}
