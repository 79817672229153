import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, EMPTY } from 'rxjs';
import { AlertComponent } from '../components/alert/alert.component';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private bsModalRef: BsModalRef;
  private alert$ = new Subject<void>();

  constructor(private bsModalService: BsModalService) {
  }

  public showAlert(initialState: any = {title: 'Alert', text: 'Alert'}): Observable<void> {
    this.closeOpenedAlert();

    if (!this.bsModalRef) {
      this.bsModalRef = this.bsModalService.show(AlertComponent, {initialState, class: 'modal-dialog-centered'});

      this.bsModalService.onHidden
        .pipe(
          take(1)
        )
        .subscribe(type => {
          this.alert$.next();
          this.bsModalRef = null;
        });

      return this.alert$.asObservable().pipe(take(1));
    } else {
      return EMPTY;
    }
  }

  private closeOpenedAlert(): void {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
      this.bsModalRef = null;
    }
  }
}
