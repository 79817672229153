import { Component, OnDestroy, OnInit } from '@angular/core';
import { TimezoneService } from '../../../../../core/services/timezone.service';
import { Timezone } from '../../../../../core/models/timezone.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { concat, Observable, Subject } from 'rxjs';
import { ButtonTypes } from '../../../../../core/types/button-types.enum';
import { faSave, faEraser } from '@fortawesome/free-solid-svg-icons';
import { LanguageService } from '../../../../../core/services/language.service';
import { LocalizationService } from '../../../../../core/services/localization.service';
import { User } from '../../../../../core/models/user.model';
import { UserService } from '../../services/user.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { AuthService } from '../../../../../auth/auth.service';
import { SettingsService } from '../../../../../core/services/settings.service';

@Component({
  selector: 'bs-own-profile',
  templateUrl: './own-profile.component.html',
  styleUrls: ['./own-profile.component.scss'],
  providers: [
    UserService
  ]
})
export class OwnProfileComponent implements OnInit, OnDestroy {
  ICONS = {
    SAVE: faSave,
    DISCARD: faEraser
  };

  BSButtonTypes = ButtonTypes;
  timezones: Array<Timezone> = [];
  languages: Array<string> = [];
  localizations: Array<string> = [];
  infoForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  userSites: Array<any>;
  userSitesInitial = '';
  userData: User;

  constructor(
    private timezoneService: TimezoneService,
    private languageService: LanguageService,
    private localizationService: LocalizationService,
    private userService: UserService,
    private alertService: AlertService,
    private authService: AuthService,
    public settingsService: SettingsService
  ) {
  }

  ngOnInit(): void {
    this.timezones = this.timezoneService.timezones;
    this.languages = this.languageService.languages;
    this.localizations = this.localizationService.localizations;

    this.userService.getCurrentUserData().subscribe(user => {
      this.applyUserData(user);
      this.authService.updateUser(user, true);
      this.infoFormChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public applyChanges(): void {
    if ((this.infoForm.valid && this.infoForm.dirty) || JSON.stringify(this.userSites) !== this.userSitesInitial) {
      const passwordChangeModel = {needToChangePassword: false, newPassword: '', retypePassword: ''};
      const formValue = this.infoForm.getRawValue();
      const {newPassword, retypePassword} = formValue;

      if (newPassword) {
        if (newPassword === retypePassword) {
          passwordChangeModel.needToChangePassword = true;
          passwordChangeModel.newPassword = newPassword;
          passwordChangeModel.retypePassword = retypePassword;
        } else {
          this.alertService.showAlert({
            title: 'Passwords mismatch',
            text: 'Please use same password in "Retype password" field'
          });
          return;
        }
      }

      const updateData = Object.assign({}, this.userData);

      for (const key in formValue) {
        if (formValue.hasOwnProperty(key) && updateData.hasOwnProperty(key)) {
          updateData[key] = formValue[key];
        }
      }

      this.updateUserData(updateData, passwordChangeModel).subscribe((updatedUserData: User) => {
        this.applyUserData(updatedUserData);
        updatedUserData.userClientRoles = updatedUserData.userClientRoles.filter(clientRole => clientRole.permissions.length > 0);
        updatedUserData.userClientRoles.forEach(role => role.checked = role.default);
        this.authService.updateUser(updatedUserData, false, false);

        if (updatedUserData.timeZone !== this.timezoneService.userTimezone) {
          this.timezoneService.userTimezone = updatedUserData.timeZone;
        }
      }, error => {
        this.alertService.showAlert({title: 'Information is not updated', text: error});
      });
    }
  }

  private applyUserData(userData): void {
    if (!userData) {
      return;
    }

    this.userData = userData;
    this.userSites = [];
    const brands = {};
    this.userData.userClientRoles.forEach(clientRole => {
      brands[clientRole.clientId] = {
        name: clientRole.clientName,
        sites: this.userData.userClientRoles.filter(role => role.clientId === clientRole.clientId).map(site => {
          return {
            clientId: site.clientId,
            subClientId: site.subClientId,
            name: site.subClientName,
            role: site.userRole.toLowerCase(),
            isDefault: site.default
          };
        })
      };
    });

    this.userSites = Object.values(brands);
    this.userSitesInitial = JSON.stringify(this.userSites);
    const timezone = this.timezones.find(tz => tz.name === this.userData.timeZone) ?? this.timezones[0];

    this.infoForm = new FormGroup({
      userName: new FormControl({value: this.userData.userName, disabled: true}),
      email: new FormControl({value: this.userData.email, disabled: true}),
      firstName: new FormControl({value: this.userData.firstName, disabled: false}, Validators.required),
      lastName: new FormControl({value: this.userData.lastName, disabled: false}, Validators.required),
      newPassword: new FormControl({value: '', disabled: false}),
      retypePassword: new FormControl({value: '', disabled: true}),
      timeZone: new FormControl({value: timezone.name, disabled: false}),
      language: new FormControl({value: this.userData.language, disabled: false}),
      dateFormat: new FormControl({value: this.userData.dateFormat, disabled: false}),
      dateSeparator: new FormControl({value: this.userData.dateSeparator, disabled: false}),
      timeFormat: new FormControl({value: this.userData.timeFormat, disabled: false}),
      decimalSeparator: new FormControl({value: this.userData.decimalSeparator, disabled: false}),
      thousandsSeparator: new FormControl({value: this.userData.thousandsSeparator, disabled: false})
    });
  }

  private updateUserData(userData: any, passwordChange: any): Observable<any> {
    const acts = [];

    if (passwordChange.needToChangePassword) {
      acts.push(this.authService.changePassword(passwordChange.newPassword, passwordChange.retypePassword));
    }

    if (JSON.stringify(this.userSites) !== this.userSitesInitial) {
      acts.push(this.userService.updateUserRoles(this.getClientRoles()));
    }

    acts.push(this.userService.updateUser(userData));
    return concat(...acts);
  }

  private infoFormChanges(): void {
    this.infoForm.get('newPassword').valueChanges.subscribe(newPasswordValue => {
      const retypePasswordControl = this.infoForm.get('retypePassword');
      if (newPasswordValue) {
        retypePasswordControl.enable();
        retypePasswordControl.setValidators(Validators.required);
      } else {
        retypePasswordControl.disable();
        retypePasswordControl.reset();
        retypePasswordControl.clearValidators();
      }
    });
  }

  public f(control) {
    return this.infoForm.controls[control];
  }

  public isInvalid(control): boolean {
    return this.f(control).invalid && (this.f(control).dirty || this.f(control).touched);
  }

  private getClientRoles(): Array<any> {
    const roles = [];

    this.userSites.forEach(userSite => {
      userSite.sites.forEach(site => {
        roles.push({
          clientId: site.clientId,
          subClientId: site.subClientId,
          default: site.isDefault
        });
      });
    });

    return roles;
  }
}
