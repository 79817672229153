<div class="bs-input">
  <textarea
    [(ngModel)]="value"
    (ngModelChange)="change()"
    [placeholder]="placeholder ? placeholder : ''"
    [maxlength]="maxLength"
    [disabled]="disabled"
    [class.disabled]="disabled"
    class="input"
    cdkTextareaAutosize
    cdkAutosizeMinRows="1"
    (focus)="emitFocusEvent(true)"
    (blur)="emitFocusEvent(false)"
  ></textarea>
  <span
    class="bs-input-border"
    [class.invalid]="isInvalid"
  ></span>
  <span class="counter">{{ value.length }}/{{maxLength}}</span>
</div>
