<div class="extended-date-time-picker">
  <bs-date-time-range-picker
    [(ngModel)]="value.date"
    (ngModelChange)="change()"
    [disabled]="disabled"
  ></bs-date-time-range-picker>
  <bs-input
    type="number"
    [(ngModel)]="value.offset"
    (ngModelChange)="change()"
    placeholder="Days"
    [disabled]="disabled"
  ></bs-input>
</div>
