import { Component, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bs-date-time-range-picker',
  templateUrl: './date-time-range-picker.component.html',
  styleUrls: ['./date-time-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateTimeRangePickerComponent,
      multi: true
    }
  ]
})
export class DateTimeRangePickerComponent implements ControlValueAccessor, OnDestroy {
  public value: Array<any>;
  public disabled: boolean;
  public timeFormat12h = false;
  public startAt: Date;
  public endAt: Date;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  private onChange = (value: Array<any>) => {};
  private onTouched = () => {};

  constructor(private authService: AuthService) {
    const currentDate = new Date();
    this.startAt = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0);
    this.endAt = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59);

    this.authService.currentUser.pipe(
      takeUntil(this.destroy$)
    ).subscribe(user => {
      if (user) {
        this.timeFormat12h = user.timeFormat.toLowerCase() === '12h';
      }
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: Array<any>): void {
    this.value = obj || [];
  }

  change(): void {
    if (this.value.every(date => date === null)) {
      this.onChange(this.value);
      return;
    }

    if (this.value.length) {
      const idx = this.value.findIndex(date => date === null);
      if (idx >= 0) {
        this.value[idx] = new Date();
      }
    }

    this.onChange(this.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
