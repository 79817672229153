import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface Range {
  from: string;
  to: string;
}

@Component({
  selector: 'bs-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeInputComponent),
      multi: true
    }
  ]
})
export class RangeInputComponent implements ControlValueAccessor {
  public range: Range = { from: '', to: '' };
  public disabled: boolean;

  onChange = (range: Range) => {};

  onTouched = () => {};

  writeValue(range: Range): void {
    if (!range) {
      this.range.from = '';
      this.range.to = '';
    } else {
      this.range.from = range.from;
      this.range.to = range.to;
    }

    this.onChange(this.range);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  changes(): void {
    this.onChange(this.range);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
