<div class="bs-input">
  <input
    #bsInput
    [type]="type || 'text'"
    [placeholder]="placeholder ? placeholder : ''"
    [(ngModel)]="value"
    (ngModelChange)="change()"
    [disabled]="disabled"
    [ngClass]="[disabled ? 'disabled' : '', height ? height : '']"
    [style.width]="width + 'px'"
    [min]="min"
    [bsNumberFilter]="type === 'number'"
    [allowOperations]="true"
    class="input"
    [autofocus]="autofocus"
    (focus)="emitFocusEvent(true)"
    (blur)="emitFocusEvent(false)"
  />
  <span
    class="bs-input-border"
    [class.invalid]="isInvalid"
  ></span>
</div>
