import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonTypes } from '../../core/types/button-types.enum';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../core/services/alert.service';

@Component({
  selector: 'bs-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  public ButtonTypesEnum = ButtonTypes;
  public loginForm: FormGroup;
  public returnUrl: string;
  public isShowingSessionEndedMessage = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.isShowingSessionEndedMessage = !!this.route.snapshot.queryParams.sessionEnded;
  }

  private createForm(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  public logIn(): void {
    if (this.loginForm.status === 'INVALID') {
      let requiredError = false;

      for (const control in this.loginForm.controls) {
        if (this.loginForm.controls[control].status !== 'VALID' && this.loginForm.controls[control].errors.required) {
          requiredError = true;
          break;
        }
      }

      if (requiredError) {
        this.showAuthenticationError('Username and password are required!');
      } else {
        this.showAuthenticationError('Unknown error');
      }
    } else {
      this.authService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value).pipe(
        first()
      ).subscribe(_ => {
          this.router.navigate([this.returnUrl]);
        }, err => {
          if (err) {
            this.alertService.showAlert({title: 'Login error', text: err});
          }
        }
      );
    }
  }

  private showAuthenticationError(message: string): void {
    this.alertService.showAlert({title: 'Authentication error', text: message});
  }
}
