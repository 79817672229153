<div class="radio" [class.disabled]="disabled">
  <div
    *ngFor="let option of configuration"
    class="radio__button"
    [class.active]="option.option === value"
    (click)="change(option.option)"
  >
    {{ option.name }}
  </div>
</div>
