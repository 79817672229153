import { Injectable } from '@angular/core';
import { Timezone } from '../models/timezone.model';
import * as MomentTimezone from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class TimezoneService {
  public timezones: Array<Timezone> = [];
  public timezone$: BehaviorSubject<Timezone>;

  private chosenTimezone: Timezone;
  private format = 'YYYY-MM-DDTHH:mm:ssZ';

  constructor() {
    const timezones = MomentTimezone.tz.names();

    for (const key of Object.keys(timezones)) {
      const name = timezones[key];
      const splittedName = name.split('/');
      const region = splittedName.shift();
      const offset = MomentTimezone.tz(timezones[key]).format('Z');
      const [hours, minutes] = offset.split(':');
      const displayName = `UTC ${ hours[1] === '0' ? hours[0] + hours[2] : hours}${minutes !== '00' ? '.' + minutes : ''} ${ splittedName.length ? splittedName.join('/') : region }`;
      this.timezones.push({ name, displayName, offset, region });
    }

    this.chosenTimezone = this.timezones[0];
    this.timezone$ = new BehaviorSubject<Timezone>(this.chosenTimezone);
  }

  timezoneChange(timezone: Timezone): void {
    this.chosenTimezone = timezone;
    this.timezone$.next(this.chosenTimezone);
  }

  set userTimezone(name: string) {
    const timezone = this.timezones.find(tz => tz.name === name);
    if (timezone) {
      this.timezoneChange(timezone);
    }
  }

  public getTimePassed(value): string {
    const ms = moment().diff(moment(value));
    const d = moment.duration(ms);
    const years = d.years();
    const months = d.months();
    const days = d.days();
    const hours = d.hours();
    const minutes = d.minutes();
    return `${years ? years + ' years, ' : ''}${months ? months + ' months, ' : ''}${days ? days + ' days, ' : ''}${hours ? hours + ' hours, ' : ''}${minutes ? minutes + ' minutes ago' : 'ago'}`;
  }

  public addDays(startDate: Date, days: number): moment.Moment {
    return moment(startDate, this.format).add(days, 'days');
  }

  public formatDate(date: Date | moment.Moment): string {
    const datetime = moment(date);
    return datetime.format(this.format);
  }
}
