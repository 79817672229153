<div class="bs-form-control">
  <div class="bs-form-control__header">
    <label
      class="bs-form-control__header__label"
      [class.invalid]="isInvalid"
      [class.disabled]="isDisabled"
    >{{ label }}</label>
  </div>
  <div class="bs-form-control__control">
    <ng-template [ngTemplateOutlet]="controlTemplate"></ng-template>
  </div>
  <div
    class="bs-form-control__footer"
    *ngIf="hasFooter && isInvalid"
  >
    <ng-template [ngTemplateOutlet]="footerTemplate"></ng-template>
  </div>
</div>
