<div class="navbar-wrapper">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container">
      <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a routerLink="/orders" class="navbar-brand">
        <img
          src="assets/images/bluesail-black.svg"
          width="150"
          alt="BlueSail"
        />
      </a>
      <div class="collapse navbar-collapse align-items-start align-items-lg-end" [collapse]="isCollapsed" [isAnimated]="true" [class.expanded]="!isCollapsed">
        <ul class="navbar-nav ml-lg-5 mr-auto mt-2 mt-lg-0">
          <li class="nav-item mr-lg-4" routerLinkActive="active">
            <a routerLink="/orders" class="nav-link pl-2 pl-lg-0">Orders</a>
          </li>
          <li
            *ngIf="isAdmin"
            class="nav-item"
            routerLinkActive="active"
          >
            <a routerLink="/admin" class="nav-link pl-2 pl-lg-0">Administration</a>
          </li>
        </ul>
        <div class="d-flex flex-lg-row flex-column pl-2 pl-lg-0">
          <div class="btn-group timezone" dropdown>
            <fa-icon [icon]="ICONS.CLOCK"></fa-icon>
            <ng-select
              [items]="timezones"
              bindLabel="displayName"
              [(ngModel)]="chosenTimezone"
              [clearable]="false"
              groupBy="region"
              class="auto-grow navbar-select"
              (change)="timezoneChange($event)"
              [searchable]="false"
              #timezoneSelect
              (close)="emitFocusEvent(false)"
            >
              <ng-template ng-header-tmp>
                <div class="header-search">
                  <input
                    class="header-search-input"
                    type="text"
                    (input)="timezoneSelect.filter($event.target.value)"
                    placeholder="Search city..."
                    (focus)="emitFocusEvent(true)"
                    (blur)="emitFocusEvent(false)"
                  >
                  <fa-icon class="header-search-icon" [icon]="ICONS.SEARCH"></fa-icon>
                  <span class="header-search-border"></span>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="btn-group clients" dropdown>
            <fa-icon [icon]="ICONS.WORK"></fa-icon>
            <span class="bs-dropdown-header" (click)="sitesClicked()">Sites</span>
            <ng-select
              #clientsDropdown
              [items]="currentUser.userClientRoles"
              bindValue="subClientId"
              [multiple]="true"
              [(ngModel)]="chosenClients"
              [clearable]="false"
              class="auto-grow navbar-select hide-header"
              [closeOnSelect]="false"
              (close)="subClientsClosed()"
              (change)="subClientChange($event)"
            >
              <ng-template ng-option-tmp let-item="item" let-item$="item$">
                <bs-checkbox [ngModel]="item$.selected"></bs-checkbox>
                <span>{{ item.subClientName }}</span>
              </ng-template>
            </ng-select>
          </div>
          <div class="btn-group users" dropdown>
            <fa-icon [icon]="ICONS.USER"></fa-icon>
            <span class="bs-dropdown-header" (click)="userOptionsToggle()">{{ currentUser.userName }}</span>
            <ng-select
              #userOptionsDropdown
              [items]="userOptions"
              [clearable]="false"
              class="auto-grow navbar-select hide-header"
              [closeOnSelect]="true"
              (change)="userOptionClicked($event)"
            >
              <ng-template ng-option-tmp let-item="item">
                <span [ngClass]="item.className ? item.className : ''">
                  {{ item.label }} <fa-icon class="ml-2" *ngIf="item.icon" [icon]="item.icon"></fa-icon>
                </span>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
