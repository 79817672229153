import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  faHome,
  faUsers,
  faUser,
  faIdCard,
  faSignOutAlt,
  faClock,
  faGlobeAmericas,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../auth/auth.service';
import { TimezoneService } from '../../services/timezone.service';
import { Timezone } from '../../models/timezone.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { User } from '../../models/user.model';
import { UserService } from '../../../home/modules/user/services/user.service';
import { SiteService } from '../../services/site.service';
import { Permission } from '../../types/permissions.enum';
import { FocusOnInputService } from '../../services/focus-on-input.service';

@Component({
  selector: 'bs-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  ICONS = {
    WORK: faGlobeAmericas,
    HOME: faHome,
    USERS: faUsers,
    USER: faUser,
    PROFILE: faIdCard,
    LOGOUT: faSignOutAlt,
    CLOCK: faClock,
    SEARCH: faSearch
  };

  PermissionEnum = Permission;
  isCollapsed = true;
  clients = [];
  chosenClients = [];
  timezones: Array<Timezone>;
  chosenTimezone: Timezone;
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('clientsDropdown') clientsDropdown: NgSelectComponent;
  @ViewChild('userOptionsDropdown') userOptionsDropdown: NgSelectComponent;
  userOptionsIsOpened = false;
  currentUser: User;
  isAdmin: boolean;
  hasSubclientsChanges = false;
  userOptions = [
    { key: 'profile', label: 'My Profile' },
    { key: 'logout', label: 'Log Out', icon: this.ICONS.LOGOUT, className: 'text-danger' },
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
    private timezoneService: TimezoneService,
    private userService: UserService,
    private siteService: SiteService,
    private focusOnInputService: FocusOnInputService
  ) {}

  ngOnInit(): void {
    this.timezones = this.timezoneService.timezones;
    this.authService.currentUser.subscribe(user => {
      if (user) {
        this.chosenClients = [];

        user.userClientRoles
          .sort((a, b) => (a.subClientName < b.subClientName) ? 1 : ((b.subClientName < a.subClientName) ? -1 : 0))
          .sort(a => a.checked ? -1 : 1);

        user.userClientRoles.forEach(clientRole => {
          if (clientRole.checked) {
            this.chosenClients.push(clientRole.subClientId);
          }
        });

        this.currentUser = user;
        this.currentUser.userClientRoles = [...user.userClientRoles];

        this.timezoneService.userTimezone = this.currentUser.timeZone;

        const site = this.currentUser.userClientRoles.find(clientRole => clientRole.permissions.includes(this.PermissionEnum.USERS_EDIT));
        this.isAdmin = this.currentUser.superAdmin || !!site;
      }
    });

    this.timezoneService.timezone$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(t => this.chosenTimezone = t);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public timezoneChange(event): void {
    if (event?.target) {
      return;
    }

    this.timezoneService.timezoneChange(event);
  }

  public userOptionClicked(option): void {
    if (option) {
      switch (option.key) {
        case 'profile': {
          this.router.navigate(['/user/profile']);
          this.userOptionsDropdown.handleClearClick();
          break;
        }

        case 'logout': {
          this.authService.logout();
          this.router.navigate(['/login']);
          this.userOptionsDropdown.handleClearClick();
          break;
        }

        default: {
          break;
        }
      }
    }
  }

  public sitesClicked(): void {
    this.clientsDropdown.isOpen ? this.clientsDropdown.close() : this.clientsDropdown.open();
  }

  public userOptionsToggle(): void {
    this.userOptionsIsOpened ? this.userOptionsDropdown.close() : this.userOptionsDropdown.open();
    this.userOptionsIsOpened = !this.userOptionsIsOpened;
  }

  public subClientChange(event) {
    if (event?.target) {
      return;
    }

    this.currentUser.userClientRoles.forEach(role => {
      const existingRole = event.find(changedRole => {
        return changedRole.clientId === role.clientId && changedRole.subClientId === role.subClientId;
      });

      role.checked = !!existingRole;
    });

    this.authService.updateUser(this.currentUser, false);
    this.hasSubclientsChanges = true;
  }

  public subClientsClosed(): void {
    if (this.hasSubclientsChanges) {
      this.siteService.emitEvent({ changed: true });
    }

    this.hasSubclientsChanges = false;
  }

  public emitFocusEvent(value: boolean): void {
    this.focusOnInputService.inputFocus = value;
  }
}
