import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../core/services/alert.service';
import { ButtonTypes } from '../../core/types/button-types.enum';

@Component({
  selector: 'bs-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  BSButtonTypes = ButtonTypes;
  changePasswordForm: FormGroup;
  token: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.token = params.get('resetToken');

      if (!this.authService.token && !this.authService.currentUserValue?.token && !this.token) {
        this.router.navigate(['/login']);
        return;
      } else {
        this.changePasswordForm = new FormGroup({
          password: new FormControl('', [Validators.required])
        });
      }
    });
  }

  changePassword(): void {
    const password = this.changePasswordForm.value.password;
    if (password) {
      this.authService.changePassword(password, password, this.token).subscribe(_ => {
        this.alertService.showAlert({ title: 'Success', text: 'Password was successfully changed' }).subscribe(value => {
          this.router.navigate(['/login']);
        });
      }, text => {
        this.alertService.showAlert({ title: 'Failure', text });
      });
    } else {
      this.alertService.showAlert({ title: 'Failure', text: 'Password is required' });
    }
  }
}
