<div class="holder">
  <div class="login">
    <div class="login__title">
      <h1>Log In</h1>
    </div>
    <div class="login__form">
      <form [formGroup]="loginForm" (keyup.enter)="logIn()">
        <div class="login__form__control">
          <label class="login__form__control__label">Login</label>
          <bs-input
            class="login__form__control__input"
            placeholder="Login"
            formControlName="username"
          ></bs-input>
        </div>
        <div class="login__form__control">
          <label class="login__form__control__label">Password</label>
          <bs-input
            class="login__form__control__input"
            placeholder="Password"
            formControlName="password"
            type="password"
          ></bs-input>
        </div>
        <div class="login__form__actions">
          <bs-button
            [type]="ButtonTypesEnum.PRIMARY"
            value="Log In"
            size="small"
            (click)="logIn()"
          ></bs-button>
          <bs-button
            [type]="ButtonTypesEnum.LINK"
            value="Forgot Password?"
            size="small"
            routerLink="/reset-password"
          ></bs-button>
        </div>
      </form>
    </div>
    <div class="error-message" *ngIf="isShowingSessionEndedMessage">
      Your session has expired. Please, log in again.
    </div>
  </div>
  <div class="info">
    <h1>Any issues?</h1>
    <div class="info__group">
      <b>Already a customer and need an account?</b>
      <p>Please contact your Astound Commerce Engagement Manager.</p>
    </div>
  </div>
</div>
