<div class="holder">
  <div class="reset">
    <div class="reset__title">
      <fa-icon routerLink="/login" [icon]="ICONS.BACK"></fa-icon>
      <h1>Password reset</h1>
    </div>
    <div class="reset__form" *ngIf="!resetPasswordRequestIsSent; else sent">
      <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
        <div class="reset__form__control">
          <label class="reset__form__control__label">Email address</label>
          <bs-input
            class="reset__form__control__input"
            placeholder="Enter email address"
            formControlName="email"
          ></bs-input>
        </div>
        <div class="reset__form__actions">
          <bs-button
            [type]="ButtonTypesEnum.PRIMARY"
            size="small"
            value="Send reset link"
            (click)="resetPassword()"
          ></bs-button>
        </div>
      </form>
    </div>
    <ng-template #sent>
      <div class="reset__sent">
        <div class="reset__sent__info">
          <b>The Reset link was sent to your email address.</b>
          <p>Please also check ‘Spam’ folder. Please contact your Astound Commerce Engagement Manager if you face any issues.</p>
        </div>
        <div class="reset__sent__actions">
          <bs-button
            [type]="ButtonTypesEnum.PRIMARY"
            size="small"
            value="Try other email"
            (click)="resetPasswordRequestIsSent = false"
          ></bs-button>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="info">
    <h1>Any issues?</h1>
    <div class="info__group">
      <p>Reset link will be sent to your email address within 10 minutes.</p>
    </div>
    <div class="info__group">
      <p>Please also check ‘Spam’ folder. Please contact your Astound Commerce Engagement Manager if you face any issues.</p>
    </div>
  </div>
</div>
