import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FocusOnInputService } from '../../services/focus-on-input.service';

@Component({
  selector: 'bs-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextareaComponent,
      multi: true
    }
  ]
})
export class TextareaComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() maxLength: number;
  @Input() isInvalid: boolean;

  public value = '';
  public disabled: boolean;
  private onChange = (value: string) => {};
  private onTouched = () => {};

  constructor(private focusOnInputService: FocusOnInputService) { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: string): void {
    this.value = obj || '';
  }

  change(): void {
    this.onChange(this.value);
  }

  public emitFocusEvent(value: boolean): void {
    this.focusOnInputService.inputFocus = value;
  }
}
