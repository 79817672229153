import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { User } from '../../../../core/models/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  public getCurrentUserData(): Observable<User> {
    return this.httpClient.get<User>(`${environment.apiUrl}/user/`);
  }

  public updateUser(userData): Observable<any> {
    delete userData.newPassword;
    delete userData.retypePassword;
    delete userData.userClientRoles;
    delete userData.token;
    return this.httpClient.put(`${environment.apiUrl}/user/`, userData);
  }

  public updateUserRoles(roles: Array<any>): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/user/roles`, { roles });
  }
}
